<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow width="600" height="180" :steps="workflowSteps" :currentStep="workflowIndex" :labelWidth="200"
        :lineWidth="140"></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="10">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="ACS > rôles des collaborateurs" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div>
                Consulter les rôles associés spécifiquement aux collaborateurs
              </div>

              <v-spacer></v-spacer>

              <!-- Bouton de modification -->
              <v-btn v-if="canEditRole && !modeEdition" icon color="primary" @click="clickOnModeEdition"
                :disabled="loading">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-row justify="end" no-gutters v-if="modeEdition">
                <v-btn class="btn" color="primary" text @click="clickOnCancelEdit" :disabled="loading">
                  Quitter l'édition
                </v-btn>

                <v-btn icon color="primary" :disabled="!hasChanged || loading" @click="save">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>

          <!-- Table matrice des droits -->
          <v-card-text class="pt-1">
            <v-data-table :headers="availableHeaders" :items="availableItems" item-key="email" :search="search"
              :custom-filter="filterOnlyCapsText" disable-pagination hide-default-footer sort-by="email">
              <!-- Template d'analyse des items en fonction des colonnes pour modifier l'affichage du contenu des cellules -->
              <template v-for="head in availableHeaders" v-slot:[`item.${head.value}`]="{ item }">
                <div :key="head.value">
                  <!-- Colonne user on affiche l'email -->
                  <div v-if="head.value == 'email'">
                    {{ item[head.value] }}
                  </div>

                  <!-- Pour les autres colonnes, on traite l'affichage -->
                  <div v-else>
                    <v-row no-gutters justify="center">
                      <v-icon v-if="item[head.value].hasRole && !modeEdition" small class="mr-2" color="primary">
                        mdi-check
                      </v-icon>

                      <v-checkbox v-if="modeEdition && !item[head.value].isHerited"
                        off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-outline"
                        v-model="item[head.value].hasRole" color="primary" hide-details class="my-0"
                        @click="onClickCheckBox(item['email'], head.value)"></v-checkbox>

                      <!-- Tooltip conteant l'icône d'héritage -->
                      <v-tooltip bottom v-if="item[head.value].isHerited">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" color="secondary" v-bind="attrs" v-on="on">
                            mdi-account-switch-outline
                          </v-icon>
                        </template>
                        <span>Héritage par rôle supérieur</span>
                      </v-tooltip>
                    </v-row>
                  </div>
                </div>
              </template>

              <template v-slot:top>
                <v-row class="my-4">
                  <v-autocomplete v-model="selectedRoles" :items="headers" item-text="name" return-object
                    placeholder="Choisir un ou plusieurs rôle" class="mx-4 my-0 pa-0" no-data-text="aucun rôle"
                    clearable multiple>
                  </v-autocomplete>

                  <v-checkbox off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-outline"
                    v-model="viewUsersHasRole" color="primary" hide-details class="my-0 mx-4"
                    label="Restreindre les collaborateurs au(x) rôle(s) sélectionné(s)"></v-checkbox>

                  <v-spacer></v-spacer>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions v-if="modeEdition">
            <v-spacer></v-spacer>
            <v-btn outlined class="ma-2 px-4 btn" color="primary" :disabled="!hasChanged || loading" @click="save">
              <div class="capitalize">enregistrer</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AlertNotSavedModifsComponent :show="showAlertQuit" @quit="onQuitAlert" @notquit="onNotQuitAlert" />

    <!-- afficher des messages -->
    <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout" :left="snackbarLeft"
      :right="snackbarRight" :top="snackbarTop" :bottom="snackbarBottom">{{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import * as exceptions from "@/service/exception_to_message.js";

import AcsService from "@/service/acs/acs_service.js";
import AuthAdminService from "@/service/backend/auth_admin_service.js";

import * as logger from "@/tools/logger.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "UsersSpécificsRoles",

  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],

  data() {
    return {
      /** le service de gestion des droits */
      serviceAcs: null,
      serviceAuthAdminBackend: null,

      /** variable pour la saisie de recherche dans les rows*/
      search: "",

      /** chargement des datas */
      loading: false,

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: false,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      /**les entêtes de la table : rôle + fonctions  */
      headers: [],

      /** les rôles source */
      rolesSource: [],

      /** les lignes de la table (users) */
      items: [],
      /** les lignes de référence */
      itemsReference: [],
      /** les objects source de la table */
      itemsSource: [],

      /** Les rôles sélectionnés dans la combo */
      selectedRoles: [],

      /** Indique si l'on affiche tous les collaborateur (false) ou uniquement ceux qui un des rôles sélectionné (true) */
      viewUsersHasRole: true,

      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditAcs],
    };
  }, // END OF DATA

  methods: {
    // Méthode de chargement des datas
    async load() {
      try {
        logger.debug("Chargement des données de la vue.");
        this.loading = true;

        // Récupération de la liste des rôles
        let allRoles = await this.serviceAuthAdminBackend.getRoles();
        this.rolesSource = JSON.parse(JSON.stringify(allRoles));

        // Récupération des utilisateurs ayant des rôles explicites.
        let allUsers =
          await this.serviceAuthAdminBackend.getDetailForAllCollaborater();
        // Sauvegarde de la source
        this.itemsReference = JSON.parse(JSON.stringify(allUsers));

        // Récupération de la liste des noms des rôles
        this.headers = allRoles.map((role) => role.name).sort();

        // on ajoute l'héritage aux users
        for (let user of allUsers) {
          // logger.debug("user", user);
          user.role = this.serviceAcs.buildInheritance(
            allRoles,
            user.rules.authorized
          );
        }

        this.items = this.buildItems(allUsers);
        //logger.debug("All Users buildés", this.items[0]);
        this.itemsSource = JSON.parse(JSON.stringify(this.items));

        //logger.debug("roles", this.headers);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Diff et sauvegarde des modifs */
    async save() {
      try {
        this.loading = true;

        logger.debug("SAVE");
        let userChanged = this.findChanged();

        // Des collaborateurs ont changés ?
        if (userChanged.length > 0) {
          // parcours des collaborateurs qui ont changés
          for (let user of userChanged) {
            let roles = [];

            // Parcours les role pour en extraire ceux du user
            for (let role of this.headers) {
              if (user[role].hasRole) {
                roles.push(role);
              }
            }
            // Récupération du collaborateur référent
            let userRef = this.itemsReference.find(
              (i) => i.collaboratorUuid == user.collaboratorUuid
            );

            // Affectation des nouveau rôles à l'utilisateur de référence
            userRef.rules.authorized = roles;

            // Sauvegarde en base des droits de l'utilisateur
            await this.serviceAuthAdminBackend.updateRolesForCollaborater(
              userRef,
              userRef.collaboratorUuid
            );
          }
          // recopie des items dans la source pour la conservation des modifs
          this.itemsSource = JSON.parse(JSON.stringify(this.items));
          this.modeEdition = false;
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Trouve tous les rôles qui ont changés sur l'ensemble des users */
    findChanged() {
      let roleHasChanged = [];

      // Parcours des users (rows)
      for (let row of this.items) {
        // Récupération du collaborateur en cours dans la source
        let rowSource = this.itemsSource.find((r) => r.collaboratorUuid == row.collaboratorUuid);

        // Parcours de l'ensemble des rôles
        for (let head of this.headers) {
          // Si la valeur d'attribution a changée entre l'item en cours et sa source
          if (row[head].hasRole != rowSource[head].hasRole) {
            roleHasChanged.push(row);
            break;
          }
        }
      }
      return roleHasChanged;
    },

    /** Construction des items (rows) de la vue */
    buildItems(allUsers) {
      let items = [];

      // Parcours la liste des utilisateur
      for (let user of allUsers) {
        let item = {};

        item.collaboratorUuid = user.collaboratorUuid;
        item.email = user.email || `${user.collaboratorUuid} (${user.status})`;

        // Parcours tous les rôles
        for (let role of this.headers) {
          // Objet d'info pour user/rôle
          item[role] = {};
          item[role].hasRole = false;
          item[role].isHerited = false;

          // Cherche le role dans ceux de l'utilisateur
          let found = user.role.find((r) => r.name == role);

          // Rôle trouvé chez l'utilisateur
          if (found) {
            //Rôle non hérité
            if (!found.herited) {
              item[role].hasRole = true;
            } else {
              // Rôle hérité
              item[role].isHerited = true;
            }
          }
        }
        items.push(item);
      }
      return items;
    },

    /** Méthode de recherche d'un rôle */
    filterOnlyCapsText(value, search) {
      search = search.toUpperCase();

      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },

    /** Traite  le click sur une checkbox
     * Reçois la ligne et la colonne
     * en fonction, met ou pas l'héritage
     */
    onClickCheckBox(row, col) {
      // Récupère la ligne dans la liste d'items
      let item = this.items.find((i) => i.email == row);

      // Récupère l'état de chack de la cellule
      let check = item[col].hasRole;

      // Récupère le rôle dans la liste source
      let role = this.rolesSource.find((r) => r.name == col);

      // Parcours les rôles hérités de la liste
      for (let r of role.grant) {
        item[r].isHerited = check;

        if (check) {
          item[r].hasRole = false;
        }
      }
    },

    /** Annule le mode édition et remet les modifications dans l'état initial */
    clickOnCancelEdit() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function (value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.items = JSON.parse(JSON.stringify(this.itemsSource));
          }
        };
      } else {
        this.modeEdition = false;
      }
    },

    /** Click évènement sur le bouton vue d'édition */
    clickOnModeEdition() {
      this.modeEdition = true;
    },
  }, // END OF METHOD

  computed: {
    /** Construit la liste des colonnes de la matrice en fonction de :
     *  - les éléments qui sont sélectionnés dans la combo
     *  - si pas de sélection--> affiche uniquement les rôles qui sont séttés
     */
    availableHeaders() {
      let cols = [];

      let head = [];

      // Si des rôles sont sélectionnés, head contient le nom de tous ces rôles
      if (this.selectedRoles.length > 0) {
        head = this.selectedRoles;
      } else {
        // Aucun rôle sélectionné, on affiche que les rôles qui ont un setting
        // parcours de chacun des rôles
        for (let role of this.headers) {
          // Parcours des rows
          for (let user of this.items) {
            // User a le rôle, on affiche le rôle
            if (user[role].hasRole || user[role].isHerited) {
              head.push(role);
              break;
            }
          }
        }
      }

      cols.push({ text: "Collaborateur(s)", value: "email", width: "250px" });

      for (let h of head) {
        let col = {};
        col.text = h;
        col.value = h;
        col.align = "center";
        col.sortable = false;
        (col.width = "50px"), cols.push(col);
      }
      return cols;
    },

    /** retourne tous les rows à afficher */
    availableItems() {
      // On veut voir tous les utilisateurs
      if (!this.viewUsersHasRole) {
        return this.items;
      }

      // Pas de rôles sélectionné on affiche tous les utilisateurs
      if (this.selectedRoles == null || this.selectedRoles.length == 0) {
        return this.items;
      }

      let rows = [];
      // Parcours des rôles sélectionnés
      for (let role of this.selectedRoles) {
        // Parcours des utilisateurs
        for (let user of this.items) {
          // Si l'utilisateur a le rôle
          if (user[role].hasRole || user[role].isHerited) {
            // L'utilisateur a-t-il déjà été ajouté
            let found = rows.find((u) => u.collaboratorUuid == user.collaboratorUuid);
            if (!found) {
              rows.push(user);
              continue;
            }
          }
        }
      }

      return rows;
    },

    /** retourne vrai si un des items à changé par rapport à sa source */
    hasChanged() {
      let changed = false;

      // Le tableau source est null --> le modèle ne peut avoir changé
      if (!this.itemsSource) return false;

      let find = this.findChanged();
      if (find.length > 0) {
        changed = true;
      }

      return changed;
    },
  }, // END OF COMPUTED

  mounted() {
    // Configure les éléments si on peut éditer les rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    // Instanciation de la classe service
    this.serviceAcs = new AcsService(this.$api.getAcsApi());
    this.serviceAuthAdminBackend = new AuthAdminService(
      this.$api.getAuthAdminApi()
    );

    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.load();
  },
};
</script>
